<template>
  <div class="stops">
    <StopSelector
      v-model="pickup"
      type="pickup"
      :stopInformation="pickup"
      :chosenLoad="chosenLoad"
      :salesforceAccounts="salesforceAccounts"
      ref="pickup-stop"
    />
    <StopSelector
      v-model="delivery"
      type="delivery"
      :stopInformation="delivery"
      :chosenLoad="chosenLoad"
      :salesforceAccounts="salesforceAccounts"
      ref="delivery-stop"
    />
  </div>
</template>

<script>
import StopSelector from "./StopSelector.vue";

export default {
  /* eslint-disable prefer-destructuring */
  name: "Stops",
  props: {
    chosenLoad: Object,
    salesforceAccounts: Array,
  },
  components: {
    StopSelector,
  },
  data() {
    return {
      pickup: null,
      delivery: null,
    };
  },
  created() {
    if (this.chosenLoad) {
      this.pickup = this.chosenLoad.stops.find((stop) => stop.is_pickup);
      this.delivery = this.chosenLoad.stops.find((stop) => stop.is_dropoff);
    }
  },
  methods: {
    changeView(view) {
      if (this.$route.name !== view) {
        this.$router.push({ name: view });
      }
    },
    validateFormfields() {
      const pickupValidation = this.$refs["pickup-stop"].validateFormfields();
      const deliveryValidation = this.$refs["delivery-stop"].validateFormfields();
      const pickupDateValidation = this.$refs['pickup-stop'].validateExpectedDate();
      if (pickupValidation && deliveryValidation && pickupDateValidation) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.stops {
  @extend %flex-row-evenly;
}

///****Responsive styles****\\\\
@media (max-width: 613px) {
  .stops {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }
}
@media (max-width: 330px) {
  .stops {
    margin-top: 5%;
  }
}
</style>
