<template>
  <div class="select-contact-modal">
    <SearchInput
      v-if="contactsByAccount.length > 0"
      :label="$t('stopsInfo.createStopInfo.chooseContact')"
      class="contact-list"
      v-model="selectedContact"
      @search="searchWord($event)"
      @selectOption="selectSuggestion($event)"
      @blur="updateSuggestion()"
      :suggestions="filteredContact"
      :dropdown="true"
      ref="contacts-searcher"
      field="name"
      :key="'searcher' + remountSearcher"
    >
      <template v-slot:icon>
        <i class="ion ion-person icon icon-template"></i>
      </template>
      <template v-slot:main-action>
        <div class="button-content" @click="showCreateContact()">
          <i class="ion ion-add-outline"></i>
          <span>{{ $t("stopsInfo.createStopInfo.createNewContact") }}</span>
        </div>
      </template>
      <template v-slot:dropdown-option="slotProps">
        <div class="list-content-hover">
          <div class="list-content list-content--label">
            <p>{{ slotProps.option.label }}</p>
            <i class="ion ion-mail-outline"></i>
            <span>{{ slotProps.option.email }}</span>
            <br />
            <i class="ion ion-call-outline"></i>
            <span>{{ slotProps.option.phone }}</span>
          </div>
        </div>
      </template>
    </SearchInput>
    <div class="select-contact-modal__info-group" v-if="selectedContact && !contactInputs">
      <span>
        <i class="ion ion-mail-outline icon"></i>
        {{ selectedContact.email }}
      </span>
      <span>
        <i class="ion ion-call-outline icon"></i>
        {{ selectedContact.phone }}
      </span>
    </div>
    <ContactForm
      v-if="contactInputs"
      v-model="contactData"
      :contactInformation="selectedContact"
      :key="keyState"
      previousModal="selectContact"
      ref="contact-form"
    />
  </div>
</template>

<script>
import * as yup from "yup";
import ValidateFieldsError from "../../../../Extend/ValidationCreateContactSalesforce";
import ContactForm from "./ContactForm.vue";

export default {
  name: "ContactSearcher",
  props: {
    contactsByAccount: Array,
    selectedAccount: Object,
    chosenContact: Object,
  },
  components: {
    ContactForm,
  },
  mixins: [ValidateFieldsError],
  data() {
    return {
      selectedContact: null,
      fieldsMissing: false,
      filteredContact: [{ options: [] }],
      loading: false,
      contactInputs: false,
      contactData: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        name: "",
      },
      keyState: 0,
      remountSearcher: 0,
    };
  },
  watch: {
    loading() {
      this.$emit("setLoading", this.loading);
    },
    contactData: {
      deep: true,
      handler() {
        this.$emit("input", this.contactData);
      },
    },
    contactsByAccount() {
      if (this.contactsByAccount.length === 0) {
        this.contactInputs = true;
      } else {
        this.filteredContact[0].options = this.contactsByAccount.map((element) => ({
          ...element,
          phone: element.phone.replace(/[^\d]/g, ""),
          label: `${element.name}`,
        }));
      }
    },
  },
  created() {
    if (this.chosenContact) {
      this.setChosenContactInfo();
    }
  },
  methods: {
    setChosenContactInfo() {
      this.selectedContact = { ...this.chosenContact };
    },
    searchWord(event) {
      this.contactInputs = false;
      if (!event.query.trim().length) {
        let options = [];
        if (this.contactsByAccount.length) {
          options = this.contactsByAccount.map((element) => ({
            ...element,
            label: `${element.name}`,
          }));
        }
        this.filteredContact = [{ options }];
      } else {
        this.filteredContact[0].options = this.contactsByAccount
          .filter((element) => element.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1)
          .map((element) => ({
            ...element,
            label: `${element.name}`,
          }));
      }
    },
    selectSuggestion(event) {
      this.loading = true;
      this.contactData.first_name = event.first_name;
      this.contactData.last_name = event.last_name;
      this.contactData.phone = event.phone;
      this.contactData.email = event.email;
      this.contactData.name = event.name;
      this.selectedContact = event;
      this.contactInputs = false;
      if (!this.validatePropsFormat(event)) {
        this.contactInputs = true;
      }
      this.keyState += 1;
      this.loading = false;
    },
    updateSuggestion() {
      if (this.selectedContact !== null && this.contactData.name !== this.selectedContact.name) {
        this.contactData.first_name = "";
        this.contactData.last_name = "";
        this.contactData.phone = "";
        this.contactData.email = "";
        this.contactData.name = "";
        this.selectedContact = "";
        this.keyState += 1;
      }
    },
    validatePropsFormat(contactForm) {
      const contactSchema = yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        phone: yup
          .string()
          .required()
          .test((value) => {
            if (value.startsWith("1") && value.length !== 11) {
              return false;
            }
            if (value.length !== 10) {
              return false;
            }
            return true;
          }),
        email: yup.string().email().required(),
      });
      const isValid = contactSchema.isValidSync(contactForm, {
        abortEarly: false,
      });
      return isValid;
    },
    validateForm() {
      if (this.contactInputs) {
        return this.$refs["contact-form"].validateFormfields();
      }
      return this.selectedContact !== null;
    },
    showCreateContact() {
      this.selectedContact = null;
      this.contactInputs = true;
      this.$refs["contacts-searcher"].toggleDropdown();
      this.remountSearcher += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-contact-modal {
  position: relative;
  background-color: $color-gray-light;
  border-radius: 10px;
  font-family: $font-family-portal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    @include modals-title;
  }
  &__button {
    @extend %flex-center-center;
    padding: 5px 20px;
    margin-top: 5%;
    width: 30%;
    height: 40px;
    border-radius: 13px;
    @include font-button-text;
    font-weight: 400;
    &--save {
      background-color: $color-save-button;
      color: $color-white;
      border: none;
      &:hover {
        background-color: $color-save-button;
      }
    }
    &--cancel {
      @include secondary-button();
      width: 30%;
    }
  }
  &__button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  &__info-group {
    @include font-standart-text;
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    width: 100%;
    padding-top: 1%;
    padding-left: 1%;
    span {
      margin: 1% 0 1% 0;
      color: $color-primary-company;
    }
    i {
      color: $color-border-container;
      margin-right: 3px;
    }
  }
}
hr {
  width: 100%;
  margin-top: 0px;
}
.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 5px 0px;
  color: $color-primary-company;
  border-bottom: 1px solid $color-border-container;
  @include font-standart-text;
  text-align: center;
  cursor: pointer;
  i {
    font-size: 40px;
    margin-right: 25px;
  }
  span {
    font-weight: bold;
  }
}
.list-content {
  @include font-standart-text;
  cursor: pointer;
  color: $color-primary-company;
  width: 90%;
  margin: auto;
  padding: 5px;
  p {
    margin: 0px;
    font-weight: bold;
  }
  i {
    color: $color-border-container;
    margin-right: 7px;
  }
}
.icon-template {
  color: $color-border-container;
}
.list-content-hover {
  :hover {
    background-color: #e8ecee;
  }
}
.contact-info {
  width: 100%;
}
::v-deep .search-input {
  border: 1px solid $color-border-container;
}
::v-deep .create-contact-inputs {
  width: 100%;
}
::v-deep .create-contact-inputs__contact {
  display: flex;
  flex-direction: column;
}
::v-deep .input-container {
  max-width: none;
  margin: 20px 0 0 0;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}

::v-deep {
  .search-input__input {
    font-size: 0.875rem;
  }
}
</style>
