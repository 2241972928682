<template>
  <el-date-picker
    class="date-picker"
    :class="expectedDatePickup ? '' : 'missing-field'"
    type="date"
    :placeholder="$t('stopsInfo.createStopInfo.estimatedPickupDate')"
    v-model="expectedDatePickup"
    :picker-options="datePickerOptions"
    format="MM-dd-yyyy"
    :disabled="!stopData"
  >
  </el-date-picker>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ExpectedDatePicker",
  props: {
    stopData: Object,
    newDate: String,
  },
  data() {
    return {
      expectedDatePickup: null,
      datePickerOptions: {
        disabledDate(date) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date < today || date.getDay() === 6 || date.getDay() === 0;
        },
      },
    };
  },
  created() {
    this.expectedDatePickup = this.newDate;
  },
  watch: {
    expectedDatePickup(value) {
      const date = dayjs(value).format("MM-DD-YYYY");
      this.$emit("input", date);
    },
  },
  methods: {
    validateExpectedDate() {
      if (!this.expectedDatePickup) {
        return false;
      }
      return true;
    }
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  margin-top: 10px;
  width: 100%;
  display: flex;
  :disabled {
    cursor: unset !important;
  }
  @media (max-width: 560px) {
    margin-bottom: 10px;
  }
}
.missing-field ::v-deep .el-input__inner {
  background: $missing-fields;
}
</style>
