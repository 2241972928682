<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <div class="create-account-modal">
        <div class="create-account-modal__header">
          <div class="create-account__title">
            <h3 class="create-account-modal__title">
              {{ $t("stopsInfo.createStopInfo.stopDetails") }}
            </h3>
          </div>
          <div class="create-account__button">
            <button @click="closeModal" class="close-button">
              <i class="ion ion-close-circle-outline close-button__icon"></i>
            </button>
          </div>
        </div>
        <hr />
        <span class="create-account-modal__subtitle">
          {{ $t("stopsInfo.createStopInfo.accountInformation") }}</span
        >
        <CreateAccountInputs :newAccountInfo="newAccountInfo" ref="account" />
        <span class="create-account-modal__subtitle">
          {{ $t("stopsInfo.createStopInfo.contactInformation") }}</span
        >
        <CreateContactInputs :newContactInfo="newContactInfo" ref="contact" />
        <div class="checkbox-container">
          <b-form-checkbox
            v-model="newAccountInfo.createLocationInBd"
            id="checkbox"
            :binary="true"
            @click.stop
            @click.prevent
          />
          <label for="checkbox">
            <span>Save in My Locations</span>
          </label>
        </div>
        <button
          class="create-account-modal__button"
          @click="saveData()"
          :disabled="
            infoAccountModal &&
            infoAccountModal.account &&
            infoAccountModal.account.account_id &&
            infoAccountModal.contact != null
          "
        >
          {{ $t("stopsInfo.createStopInfo.save") }}
        </button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";
import CreateAccountInputs from "./CreateAccountInputs.vue";
import CreateContactInputs from "./CreateContactInputs.vue";

export default {
  name: "CreateAccountModal",
  props: { infoAccountModal: Object },
  components: {
    CreateAccountInputs,
    CreateContactInputs,
  },
  mixins: [FormatPhoneNumber],
  data() {
    return {
      fieldsMissingAccount: false,
      fieldsMissingContact: false,
      newAccountInfo: {
        name: "",
        country_code: "US",
        city: "",
        state_code: "",
        zip_code: "",
        street: "",
        createLocationInBd: false,
      },
      newContactInfo: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      loading: false,
    };
  },
  created() {
    if (this.infoAccountModal.account) {
      Object.keys(this.infoAccountModal.account).forEach((key) => {
        this.newAccountInfo[key] = this.infoAccountModal.account[key];
      });
      if (this.infoAccountModal.contact) {
        Object.keys(this.infoAccountModal.contact).forEach((key) => {
          this.newContactInfo[key] = this.infoAccountModal.contact[key];
        });
      }
    }
  },
  methods: {
    async saveData() {
      const accountValidation = await this.$refs.account.validateFormfields();
      const contactValidation = await this.$refs.contact.validateFormfields();
      if (!accountValidation || !contactValidation) {
        this.swal({
          title: `${this.$t("stopsInfo.createStopInfo.fieldsMissing")}`,
          text: "Please, try again.",
          icon: "error",
          dangerMode: true,
        });
      } else if (accountValidation && contactValidation) {
        this.loading = true;
        // Data for salesforce account
        const dataAccount = {
          name: this.newAccountInfo.name,
          street: this.newAccountInfo.street,
          city: this.newAccountInfo.city,
          zip_code: this.newAccountInfo.zip_code,
          country_code: this.newAccountInfo.country_code,
          state_code: this.newAccountInfo.state_code,
          createLocationInBd: this.newAccountInfo.createLocationInBd,
          //   // ONLY FOR TEST ACCOUNT
          account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
          // account_id: this.$store.getters["login/getTokenInfo"].accounts[0],
        };
        if (this.$options.filters.checkPermission("post:salesforce-account")) {
          const responseCreateAccount = await this.$store.dispatch(
            "load/stops/createSalesforceAccounts",
            dataAccount
          );
          const dataContact = {
            first_name: this.newContactInfo.first_name,
            last_name: this.newContactInfo.last_name,
            phone: this.phoneNumberWithoutFormat(this.newContactInfo.phone),
            email: this.newContactInfo.email,
            // ONLY FOR TEST ACCOUNT
            account_id: responseCreateAccount.id_salesforce,
            // account_id: this.$store.getters["login/getTokenInfo"].accounts[0],
          };
          if (this.$options.filters.checkPermission("post:salesforce-contact")) {
            const responseCreateContact = await this.$store.dispatch(
              "load/stops/createSalesforceContacts",
              dataContact
            );
            if (this.newAccountInfo.createLocationInBd) {
              this.$store.commit("load/stops/setNewSalesforceAccount", responseCreateAccount);
            }
            if (responseCreateAccount && responseCreateContact) {
              this.swal("Good job!", "Information created!", "success");
              const data = {
                account: responseCreateAccount,
                contact: responseCreateContact,
              };
              this.$emit("updateLocations", data);
            }
          }
        }
        this.closeModal();
        this.loading = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";
.close-button {
  @include modals-close-button;
}
.create-account-modal {
  position: relative;
  background-color: $color-gray-light;
  border-radius: 10px;
  font-family: $font-family-portal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__header {
    @include modals-header;
    width: 96%;
    margin: auto;
    justify-content: space-between;
  }
  &__subtitle {
    color: $color-border-container;
    font-weight: 600;
    text-align: left;
    @include font-standart-text;
    margin: 2%;
  }
  &__button {
    @include primary-button($heightSize: 40px);
    @extend %flex-center-center;
    padding: 5px 20px;
    margin-top: 5%;
    width: 40%;
    border-radius: 13px;
    @include font-button-text;
    font-weight: 400;
    &:disabled {
      background-color: rgba(177, 177, 177, 0.774);
      &:hover {
        background-color: rgba(177, 177, 177, 0.774);
      }
    }
  }
}
.create-account__title {
  width: 95%;
  @include modals-title;
}
hr {
  width: 96%;
  margin: 0 auto 1rem auto;
}
.checkbox-container {
  width: 100%;
  display: flex;
  margin-left: 10px;
  align-items: center;
  @media (max-width: 991px) {
    margin: 0px;
  }
  @media (max-width: 500px) {
    margin-left: 5px;
  }
}
.checkbox-container label {
  margin: 0;
  margin-top: 5px;
  font-size: 0.98rem;
  vertical-align: middle;
  color: rgb(101, 100, 100);
}
::v-deep .spinner-border {
  color: $color-primary-company;
}

::v-deep .bg-light {
  background-color: $color-gray-light !important;
}

::v-deep {
  .p-inputtext,
  .dropdown-container__selected-option {
    padding-left: 1px !important;
  }
}
</style>
