<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <div class="select-contact-modal">
        <div class="select-contact-modal__title">
          <h3>{{ $t("stopsInfo.createStopInfo.contact") }}</h3>
        </div>
        <hr />
        <!-- NEW SELECT AUTO COMPLETE -->
        <AutoComplete
          :label="$t('stopsInfo.createStopInfo.chooseContact')"
          :suggestions="filteredContact"
          :actionPerformed="true"
          ref="contacts-searcher_new"
          class="contact-list-searcher"
          @search="searchWord($event)"
          @selectOption="selectWord($event)"
          @blur="updateWord()"
          v-model="selectedContact"
        >
          <template #icon>
            <i class="ion ion-person icon icon-template"></i>
          </template>
          <template #action-in-option>
            <div class="button-content" @mousedown="showCreateContact()">
              <i class="ion ion-add-outline"></i>
              <span>{{ $t("stopsInfo.createStopInfo.createNewContact") }}</span>
            </div>
          </template>
          <template #option-item="item">
            <div class="list-content-hover">
              <div class="list-content list-content--label">
                <p>{{ item.option.label }}</p>
                <i class="ion ion-mail-outline"></i>
                <span>{{ item.option.email }}</span>
                <br />
                <i class="ion ion-call-outline"></i>
                <span>{{ formatPhoneNumber(item.option.phone) }}</span>
              </div>
            </div>
          </template>
        </AutoComplete>
        <!-- END NEW SELECT AUTO COMPLETE -->
        <div class="select-contact-modal__info-group" v-if="selectedContact && !contactInputs">
          <span>
            <i class="ion ion-mail-outline icon"></i>
            {{ selectedContact.email }}
          </span>
          <span>
            <i class="ion ion-call-outline icon"></i>
            {{ formatPhoneNumber(selectedContact.phone) }}
          </span>
        </div>
        <ContactForm
          v-if="contactInputs"
          v-model="contactData"
          :contactInformation="selectedContact"
          :key="keyState"
          previousModal="selectContact"
          ref="contact-form"
        />
        <div class="select-contact-modal__button-group">
          <button
            class="select-contact-modal__button select-contact-modal__button--cancel"
            @click="cancel()"
          >
            {{ $t("stopsInfo.createStopInfo.cancel") }}
          </button>
          <button
            class="select-contact-modal__button select-contact-modal__button--save"
            @click="saveData()"
          >
            {{ $t("stopsInfo.createStopInfo.save") }}
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import * as yup from "yup";
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";
import AutoComplete from "@/components/AutoComplete.vue";
import ValidateFieldsError from "../../../../Extend/ValidationCreateContactSalesforce";
import ContactForm from "./ContactForm.vue";

export default {
  name: "SelectContactModal",
  props: {
    contactsByAccount: Array,
    selectedAccount: Object,
    chosenContact: Object,
  },
  components: {
    ContactForm,
    AutoComplete,
  },
  mixins: [ValidateFieldsError, FormatPhoneNumber],
  data() {
    return {
      selectedContact: null,
      fieldsMissing: false,
      newContactInfo: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        name: "",
      },
      filteredContact: [{ options: [] }],
      altFilteredContact: [],
      loading: false,
      contactInputs: false,
      contactData: null,
      keyState: 0,
    };
  },
  created() {
    if (this.contactsByAccount.length === 0) {
      this.contactInputs = true;
    } else {
      this.filteredContact[0].options = this.contactsByAccount.map((element) => ({
        ...element,
        phone: element.phone.replace(/[^\d]/g, ""),
        label: `${element.name}`,
      }));
    }
    if (this.chosenContact) {
      this.setChosenContactInfo();
    }
  },
  methods: {
    setChosenContactInfo() {
      this.selectedContact = { ...this.chosenContact };
      this.selectedContact.name = this.chosenContact.first_name;
    },
    async saveData() {
      if (this.contactInputs) {
        const contactData = this.$refs["contact-form"].validateFormfields();
        if (!contactData) {
          this.swal({
            title: `${this.$t("stopsInfo.createStopInfo.fieldsMissing")}`,
            text: "Please, try again.",
            icon: "error",
            dangerMode: true,
          });
        } else {
          if (this.$options.filters.checkPermission("post:salesforce-contact")) {
            this.loading = true;
            this.$store.commit("load/tender/setRequestInProcess", true);
            // Data for salesforce contact
            this.contactData.account_id =
              this.selectedAccount.id_salesforce || this.selectedAccount.location_id;
            let responseCreateAccount;
            if (this.contactData.guid) {
              responseCreateAccount = await this.createSalesforceAccount();
              this.contactData.account_id = responseCreateAccount.id_salesforce;
              this.contactData.db_account_id = responseCreateAccount.id;
            }
            this.contactData.phone = this.phoneNumberWithoutFormat(this.contactData.phone);
            const responseCreateContact = await this.$store.dispatch(
              "load/stops/createSalesforceContacts",
              this.contactData
            );
            if (this.contactData.guid) {
              responseCreateContact.id_salesforce = this.contactData.account_id;
              responseCreateContact.guid = this.contactData.guid;
              responseCreateContact.db_account_id = this.contactData.db_account_id;
              this.$emit("getAccountData", responseCreateAccount);
              this.$emit("getContactData", responseCreateContact);
            }
            if (responseCreateContact) {
              this.swal("Good job!", "Contact created!", "success");
              this.$emit("updateContact", responseCreateContact);
              this.closeModal();
            }
          }
          this.loading = false;
        }
      } else {
        if (!this.selectedContact) {
          this.swal({
            title: `${this.$t("stopsInfo.createStopInfo.fieldsMissing")}`,
            text: "Please, try again.",
            icon: "error",
            dangerMode: true,
          });
          return;
        }
        this.$store.commit("load/tender/setRequestInProcess", true);
        this.loading = true;
        this.selectedContact.account_id = this.selectedAccount.account_id;
        if (this.selectedAccount.guid) {
          const responseCreateAccount = await this.createSalesforceAccount();
          this.selectedContact.account_id = responseCreateAccount.id_salesforce;
          this.selectedContact.db_account_id = responseCreateAccount.id;
          const responseCreateContact = await this.$store.dispatch(
            "load/stops/createSalesforceContacts",
            this.selectedContact
          );
          responseCreateContact.id_salesforce = responseCreateAccount.id_salesforce;
          responseCreateContact.guid = this.selectedContact.guid;
          responseCreateContact.db_account_id = this.selectedContact.db_account_id;
          this.$emit("getAccountData", responseCreateAccount);
          this.$emit("getContactData", responseCreateContact);
          this.$emit("updateContact", responseCreateContact);
        } else {
          this.$emit("updateContact", { ...this.selectedContact });
        }
        this.closeModal();
      }
    },
    async createSalesforceAccount() {
      const dataAccount = {
        name: this.selectedAccount.name,
        street: this.selectedAccount.street,
        city: this.selectedAccount.city,
        zip_code: this.selectedAccount.zip_code,
        country_code: this.selectedAccount.country_code,
        state_code: this.selectedAccount.state_code,
        account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
      };
      return this.$store.dispatch("load/stops/createSalesforceAccounts", dataAccount);
    },
    searchWord(event) {
      this.contactInputs = false;
      if (!event.query.trim().length) {
        let options = [];
        if (this.contactsByAccount.length) {
          options = this.contactsByAccount.map((element) => ({
            ...element,
            label: `${element.name}`,
          }));
        }
        this.filteredContact = [{ options }];
      } else {
        this.filteredContact[0].options = this.contactsByAccount
          .filter((element) => element.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1)
          .map((element) => ({
            ...element,
            label: `${element.name}`,
          }));
      }
    },
    selectWord(event) {
      this.loading = true;
      this.newContactInfo.first_name = event.first_name;
      this.newContactInfo.last_name = event.last_name;
      this.newContactInfo.phone = event.phone;
      this.newContactInfo.email = event.email;
      this.newContactInfo.name = event.name;
      this.selectedContact = event;
      this.contactInputs = false;
      if (!this.validatePropsFormat(event)) {
        this.contactInputs = true;
      }
      this.keyState += 1;
      this.loading = false;
    },
    updateWord() {
      if (this.selectedContact !== null && this.newContactInfo.name !== this.selectedContact.name) {
        this.newContactInfo.first_name = "";
        this.newContactInfo.last_name = "";
        this.newContactInfo.phone = "";
        this.newContactInfo.email = "";
        this.newContactInfo.name = "";
        this.selectedContact = "";
        this.keyState += 1;
      }
    },
    validatePropsFormat(contactForm) {
      const contactSchema = yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        phone: yup.string().required(),
        email: yup.string().email().required(),
      });
      const isValid = contactSchema.isValidSync(contactForm, {
        abortEarly: false,
      });
      return isValid;
    },
    showCreateContact() {
      this.selectedContact = null;
      this.contactInputs = true;
      this.$refs["contacts-searcher_new"].hideDropdown();
    },
    cancel() {
      this.$emit("cancelSelectContact");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.select-contact-modal {
  position: relative;
  background-color: $color-gray-light;
  border-radius: 10px;
  font-family: $font-family-portal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    @include modals-title;
  }
  &__button {
    // @include primary-button($heightSize: 40px);
    @extend %flex-center-center;
    padding: 5px 20px;
    margin-top: 5%;
    width: 30%;
    height: 40px;
    border-radius: 13px;
    @include font-button-text;
    font-weight: 400;
    &--save {
      //  @include secondary-button;
      background-color: $color-save-button;
      color: $color-white;
      border: none;
      &:hover {
        background-color: $color-save-button;
      }
    }
    &--cancel {
      @include secondary-button();
      width: 30%;
    }
  }
  &__button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  &__info-group {
    @include font-standart-text;
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    width: 100%;
    padding-top: 1%;
    padding-left: 1%;
    span {
      margin: 1% 0 1% 0;
      color: $color-primary-company;
    }
    i {
      color: $color-border-container;
      margin-right: 3px;
    }
  }
}

.contact-list-searcher {
  width: 100%;
}

hr {
  width: 100%;
  margin-top: 0px;
}
.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 5px 0px;
  color: $color-primary-company;
  border-bottom: 1px solid $color-border-container;
  @include font-standart-text;
  text-align: center;
  cursor: pointer;
  i {
    font-size: 40px;
    margin-right: 25px;
  }
  span {
    font-weight: bold;
  }
}
.list-content {
  @include font-standart-text;
  cursor: pointer;
  color: $color-primary-company;
  width: 90%;
  margin: auto;
  padding: 5px;
  p {
    margin: 0px;
    font-weight: bold;
  }
  i {
    color: $color-border-container;
    margin-right: 7px;
  }
}
.icon-template {
  color: $color-border-container;
  font-size: 1.2rem;
}

.contact-info {
  width: 100%;
}
::v-deep .search-input {
  border: 1px solid $color-border-container;
}
::v-deep .create-contact-inputs {
  width: 100%;
}
::v-deep .create-contact-inputs__contact {
  display: flex;
  flex-direction: column;
}
::v-deep .input-container {
  max-width: none;
  margin: 20px 0 0 0;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
  @media (max-width: 500px) {
    inset: 6px !important;
  }
}
::v-deep {
  .search-input__input {
    font-size: 0.875rem;
  }
  .phone-input {
    margin: -20px 0px 0px 0px;
  }
}
</style>
