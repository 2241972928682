export default {
  data() {
    return {
      stopsErrorContact: ["", "", "", ""],
      fieldsMissingContact: false,
      newContactInfo: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
    };
  },
  methods: {
    validateFieldsContact() {
      this.fieldsMissingContact = false;
      const stopsErrorAux = ["", "", "", ""];
      this.stopsErrorContact = ["", "", "", ""];
      if (this.newContactInfo.first_name === "") {
        stopsErrorAux[0] = "missing-field";
        this.fieldsMissingContact = true;
      }
      if (this.newContactInfo.last_name === "") {
        stopsErrorAux[1] = "missing-field";
        this.fieldsMissingContact = true;
      }
      if (this.newContactInfo.phone === "") {
        stopsErrorAux[2] = "missing-field";
        this.fieldsMissingContact = true;
      }
      if (this.newContactInfo.email === "") {
        stopsErrorAux[3] = "missing-field";
        this.fieldsMissingContact = true;
      }
      this.stopsErrorContact = stopsErrorAux;
      return this.fieldsMissingContact;
    },
  },
};
